'use client'

import { H2, H3 } from './ui/heading'
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs'
import { Fit, Layout, useRive, useStateMachineInput } from '@rive-app/react-canvas-lite'
import { useCallback, useEffect, useRef, useState } from 'react'
import scrollIntoView from 'scroll-into-view-if-needed'

const tabs = ['operations-manager', 'quality-manager', 'training', 'representative']

interface TeamTabProps {
  title: string
  description: string
  benefits: string[]
  tab: string
}

function TeamTab(props: TeamTabProps) {
  return (
    <TabsContent value={props.tab} className="col-span-6">
      <div className="flex gap-12 py-2 px-outer md:mt-6">
        <div className="flex-1 flex flex-col gap-4">
          <hgroup className="mb-4 grid gap-2">
            <H3>{props.title}</H3>
            <p>{props.description}</p>
          </hgroup>
          <div className="flex flex-col gap-2">
            {props.benefits.map((b, index) => (
              <div className="bg-white/50 rounded-md p-4" key={index}>
                <span>{b}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </TabsContent>
  )
}

export function TeamTabs() {
  const [tab, setTab] = useState(tabs[0])
  const [interacted, setInteracted] = useState(false)
  const tabList = useRef<HTMLDivElement>(null)

  const { RiveComponent, rive } = useRive({
    src: '/home/team_tabs.riv',
    artboard: 'Team Tabs',
    stateMachines: 'State Machine 1',
    autoplay: true,
    layout: new Layout({ fit: Fit.Contain, minX: 50, minY: 50, maxX: 100, maxY: 100 }),
  })

  const tabInput = useStateMachineInput(rive, 'State Machine 1', 'Tabs')

  useEffect(() => {
    const interval = setInterval(() => {
      setTab((s) => {
        const nextIndex = tabs.indexOf(s) === tabs.length - 1 ? 0 : tabs.indexOf(s) + 1
        if (tabInput) tabInput.value = nextIndex
        return tabs[nextIndex]
      })
    }, 5000)
    if (interacted) clearInterval(interval)
    return () => clearInterval(interval)
  }, [interacted, tabInput])

  useEffect(() => {
    const node = tabList.current?.querySelector('[data-state="active"]')
    if (node) {
      scrollIntoView(node, {
        behavior: 'smooth',
        scrollMode: 'if-needed',
        boundary: tabList.current,
      })
    }
  }, [tab])

  const handleChangeTab = useCallback(
    (t: string) => {
      setInteracted(true)
      setTab(t)

      const index = tabs.indexOf(t)
      if (tabInput) tabInput.value = index
    },
    [tabInput],
  )

  return (
    <section className="mb-20">
      <H2 className="mb-10">The platform your whole team can get behind.</H2>

      <Tabs value={tab}>
        <TabsList
          ref={tabList}
          className="container flex border-b-1 sticky md:relative top-0 bg-primary-10 rounded-none overflow-y-auto overflow-x-visible md:overflow-visible"
        >
          <TabsTrigger
            value={tabs[0]}
            onClick={() => handleChangeTab(tabs[0])}
            animate={!interacted}
            className="ml-[100%] md:ml-0"
          >
            Operations Manager
          </TabsTrigger>
          <TabsTrigger
            value={tabs[1]}
            onClick={() => handleChangeTab(tabs[1])}
            animate={!interacted}
          >
            Quality Manager
          </TabsTrigger>
          <TabsTrigger
            value={tabs[2]}
            onClick={() => handleChangeTab(tabs[2])}
            animate={!interacted}
          >
            Training
          </TabsTrigger>
          <TabsTrigger
            value={tabs[3]}
            onClick={() => handleChangeTab(tabs[3])}
            animate={!interacted}
          >
            Representative
          </TabsTrigger>
        </TabsList>
        <div className="grid-container">
          <div className="col-span-6 h-[500px] md:order-last md:h-[600px]">
            <RiveComponent />
          </div>
          <TeamTab
            title="Every Channel, Every Customer, Every Agent. "
            description="We know you have a lot of dashboards already.  With Vision, you get more than volume and AHT – you get sentiment, supervisor requests, dead air, and just about any agent or customer behavior that’s important to you, across every channel.  Drill down for more granular insights.  Find specific interactions like a needle in a haystack using our powerful search engine.  "
            benefits={[
              'Daily reports delivered straight to your inbox ',
              'See call drivers and sentiment instantly',
              'See team performance by channel, by date, by interaction type and more',
            ]}
            tab={tabs[0]}
          />
          <TeamTab
            title="You’re not out of a job. Or are you?"
            description="Quality Management is as important as ever in the Contact Center.  But it’s always been plagued by one important shortcoming – sample size.  If you wanted to analyze 100% of the calls of 20 agents, you needed at least 20 analysts!  Of course, you were lucky to get one.  No matter the size of your QA team, you were probably doing good if you got 2% of calls scored against a rubric.  That’s not even a statistically valid sample size.  Then along came Happitu Vision."
            benefits={[
              '100% of calls analyzed nearly instantly',
              'Zero bias against time call occurred, call length, call type, or agent ',
              'Instantly scales as your business grows ',
              'Provides actual performance data, not anecdotal data',
            ]}
            tab={tabs[1]}
          />
          <TeamTab
            title="Focus on Performance not Passwords!"
            description="New hires are always curious about the edge cases, derailing your training timeline.  With Guide, edge cases are covered step by step so you can focus on what matters.  Even little things like a forgotten password can set you back.  With self-service Guides for employees, trainers can focus on training instead of administrative tasks. Vision allows you to create a training library of “wow interactions” so you can let new hires hear what great sounds like for all kinds of situations.  When they start interacting with live customers, you’ll have instant access to their performance so you can deliver meaningful coaching from day 1. "
            benefits={[
              'Step by step Guides for everything, including internal onboarding processes ',
              'Never hear “We didn’t learn that in training” again – it’s all in Guide ',
              'Provide real-world examples of expected performance',
              'Deliver with Confidence! ',
            ]}
            tab={tabs[2]}
          />
          <TeamTab
            title="Deliver with Confidence! "
            description="Handling customer inquiries can be challenging.  But having the process, resources, and even the right words to say for every interaction gives front line agents the confidence of your most tenured reps, even if it’s their first day."
            benefits={[
              'Present customer information from CRM automatically',
              'Policies and procedures even for edge cases',
              'Step by step instructions with pictures for complex needs',
              'Overcome objections with perfectly worded responses',
            ]}
            tab={tabs[3]}
          />
        </div>
      </Tabs>
    </section>
  )
}
