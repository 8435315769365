'use client'

import { H2 } from '../ui/heading'
import { useRive } from '@rive-app/react-canvas-lite'

function StackGraphic() {
  const { RiveComponent } = useRive({
    src: '/home/stack.riv',
    artboard: 'stack',
    autoplay: true,
  })

  return (
    <div className="grid-container bg-gray-800 2xl:rounded-md p-10 mb-20">
      <H2 className="col-span-full text-white">
        Seamlessly plug right into your <br />
        existing stack.
      </H2>
      <div className="col-span-full">
        <RiveComponent className="w-full h-[300px] md:h-[568px]" />
      </div>
    </div>
  )
}

export default StackGraphic
