'use client'

import { Fit, Layout, useRive } from '@rive-app/react-canvas-lite'
import Link from 'next/link'
import { ReactNode } from 'react'
import { cn } from '~/utils'

type Props = {
  title: ReactNode
  artboard: string
  className?: string
  href: string
}

function ProductCard(props: Props) {
  const { RiveComponent, rive } = useRive({
    src: '/home/cards.riv',
    artboard: props.artboard,
    layout: new Layout({ fit: Fit.Cover }),
  })

  return (
    <Link
      href={props.href}
      onMouseEnter={() => {
        rive?.play()
      }}
      onMouseLeave={() => {
        rive?.pause()
      }}
      className={cn(
        `rounded-2xl relative w-full aspect-[30/37] overflow-hidden col-span-6 md:col-span-3 lg:col-span-4`,
        props.className,
      )}
    >
      <RiveComponent className="w-full h-full" />

      <h3 className="text-xl lg:text-4xl font-heading font-extrabold absolute top-10 left-10">
        {props.title}
      </h3>
    </Link>
  )
}

export default ProductCard
