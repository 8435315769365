import { Slot } from '@radix-ui/react-slot'
import { type VariantProps, cva } from 'class-variance-authority'
import { ArrowRight, Loader2 } from 'lucide-react'
import * as React from 'react'
import { cn } from 'src/utils'

const buttonVariants = cva(
  'group inline-flex items-center font-600 justify-center text-base transition ease-in-out hover:scale-105 duration-200 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-60 outline-none',
  {
    variants: {
      variant: {
        default: 'bg-secondary text-primary-foreground tracking-wider hover:bg-primary',
        primary:
          'bg-gradient-to-b from-primary-60 to-primary-40 border-primary-60 border hover:bg-primary-80 disabled:text-white/80',
        dark: 'bg-foreground hover:bg-primary-80 tracking-wide',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border border-input bg-background hover:bg-transparent hover:text-accent-foreground',
        ghost: 'hover:bg-transparent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        success: 'bg-green-500 text-white',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 px-3',
        lg: 'py-4 px-8 shadow-lg',
        icon: 'h-10 w-10 shrink-0',
      },
      rounded: {
        true: 'rounded-full',
        false: 'rounded-sm',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
      rounded: false,
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  arrow?: boolean
  loading?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      rounded,
      children,
      arrow,
      loading,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, rounded, className }))}
        ref={ref}
        {...props}
        disabled={loading || props.disabled}
      >
        {loading ? (
          <Loader2 className="h-5 w-5 animate-spin" />
        ) : (
          <>
            {children}
            {arrow && (
              <ArrowRight
                size="14"
                strokeWidth="3"
                className={cn('group-hover:translate-x-1 transition-transform relative', {
                  'ml-2': !!children,
                })}
              />
            )}
          </>
        )}
      </Comp>
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
