import(/* webpackMode: "eager" */ "/home/runner/work/happitu-website/happitu-website/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/happitu-website/happitu-website/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["PostCategories"] */ "/home/runner/work/happitu-website/happitu-website/src/components/blog/pill.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/happitu-website/happitu-website/src/components/home/cards/ProductCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/happitu-website/happitu-website/src/components/home/HomeHero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/happitu-website/happitu-website/src/components/home/StackGraphic.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TeamTabs"] */ "/home/runner/work/happitu-website/happitu-website/src/components/team-tabs.tsx");
