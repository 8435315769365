import { clsx } from 'clsx'
import { ComponentProps, FC } from 'react'
import { cn } from '~/utils'

export const H1: FC<ComponentProps<'h3'>> = (props) => (
  <h1 {...props} className={clsx('xl:text-7xl text-6xl font-800', props.className)} />
)

export const H2: FC<ComponentProps<'h3'>> = (props) => (
  <h2
    {...props}
    className={cn(
      'font-800 text-3xl md:text-5xl text-center mx-auto mb-4',
      props.className,
    )}
  />
)

export const H3: FC<ComponentProps<'h3'>> = (props) => (
  <h3 {...props} className={clsx('font-800 text-3xl', props.className)} />
)

export const H4: FC<ComponentProps<'h3'>> = (props) => (
  <h4 {...props} className={clsx('font-800  text-xl', props.className)} />
)

export const PageHeader: FC<ComponentProps<'header'>> = (props) => (
  <header
    {...props}
    className={cn(
      'text-center flex flex-col gap-4 items-center justify-center !max-w-6xl lg:!max-w-4xl min-h-[300px] pt-10 pb-10',
      props.className,
    )}
  />
)
