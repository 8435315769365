'use client'

import { buttonVariants } from '../ui/button'
import { m, useScroll } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import { useRef } from 'react'

const WORD_DURATION = 0.4

const words = ['Listen.', 'Guide.', 'Act.']

function HomeHero() {
  const ref = useRef(null)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['100%', '0%'],
    axis: 'y',
  })
  return (
    <div className="flex flex-col justify-center text-center col-span-full ">
      <div ref={ref} className="h-[400px]">
        <m.header
          className="mt-20 fixed t-[100px] left-0 right-0 px-4"
          style={{ scale: scrollYProgress, opacity: scrollYProgress }}
        >
          <hgroup className="mb-14">
            <h2 className="lg:text-8xl text-6xl font-800 text-foreground relative">
              {words.map((word, i) => (
                <m.span
                  key={i}
                  className="inline-block"
                  style={{ y: '-100%', opacity: 0 }}
                  animate={{ y: '0%', opacity: 1 }}
                  transition={{ duration: WORD_DURATION, delay: i * WORD_DURATION }}
                >
                  {word}&nbsp;
                </m.span>
              ))}
            </h2>
            <m.h1
              className="text-xl md:text-3xl font-800"
              style={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: WORD_DURATION,
                delay: words.length * WORD_DURATION,
              }}
            >
              Transform your customer experiences with Happitu.
            </m.h1>
          </hgroup>
          <m.div
            className="space-y-4 md:inline-block"
            style={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: WORD_DURATION,
              delay: (words.length + 1) * WORD_DURATION,
            }}
          >
            <div className="grid md:inline-grid gap-4 md:grid-flow-col">
              <Link
                href="/book-demo"
                className={buttonVariants({
                  variant: 'primary',
                  size: 'lg',
                  rounded: true,
                })}
              >
                Book your demo
              </Link>
            </div>
            <div className="hidden md:block text-sm text-foreground/70">
              Already have an account?
              <br />
              <Link
                href="https://app.happitu.com"
                className="underline underline-offset-4 hover:text-primary-80"
                rel="noopener noreferrer nofollow"
              >
                Log in here
              </Link>
            </div>
          </m.div>
        </m.header>
      </div>

      <m.div
        className="relative rounded-3xl mx-auto"
        style={{ opacity: 0, y: '10%' }}
        animate={{ opacity: 1, y: '0%' }}
        transition={{
          delay: (words.length + 1) * WORD_DURATION,
          duration: WORD_DURATION + 0.25,
        }}
      >
        <Image
          src="/home/hero.png"
          alt="App"
          width={1200}
          height={600}
          className="p-2"
          sizes="(max-width: 1200px) 100vw, 1200px"
        />
      </m.div>
    </div>
  )
}

export default HomeHero
